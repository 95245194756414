import React from "react"
import SEO from "../components/SEO"

export default function Home() {
    return (
        <>
        <SEO />
        <div id="page">
            <header>
                <img src="app_logo.png" />
                <h1>VocalChords</h1>
                <h2>Quickly Record Audio Notes</h2>
            </header>
            <div id="content">
                <a href='https://play.google.com/store/apps/details?id=com.aengusmcmillin.vocalchords&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                <p>Designed to be a simple and pleasant to use user experience.</p>
                <ul>
                    <li>Press and hold the button in the middle to record</li>
                    <li>Add tags to stay organized</li>
                    <li>Search through titles and tags to find what you are looking for</li>
                    <li>Press and hold recording titles to rename</li>
                    <li>Swipe left to delete recordings</li>
                    <li>Swipe right to share the audio with other compatible apps on your phone</li>
                    <li>Dark Mode for the moody times</li>
                </ul>
                <p>
                    There are no ads, and you get the full experience as soon as you install. Free to use up to 50 recordings, 
                    with a small one time purchase to unlock unlimited recordings forever. Or, and here is a little tip, 
                    if $3 is too much, you can share to export your recordings and delete them to get back space.
                </p>
                <div id="center">
                    <img src="screen0.png" />
                    <img src="screen1.png" />
                    <img src="screen2.png" />
                    <img src="screen3.png" />
                </div>
            </div>
            <footer>
                <span>Developed by <a href="https://aengusmcmillin.com">Aengus McMillin</a></span>
                <span><a href="privacypolicy">Privacy Policy</a> -- <a href="mailto:hello@aengusmcmillin.com">Contact</a></span>
            </footer>
        </div>
        </>
    )
}

